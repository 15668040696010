import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	ai_topics_surface_header_user: {
		id: 'search_ai.ai_topics.surface.header',
		defaultMessage: 'Topics this person knows about',
		description: 'Header text for the topics surface in user profiles',
	},
	ai_topics_surface_header_generic: {
		id: 'search_ai.ai_topics.related_topics.header',
		defaultMessage: 'Related topics',
		description: 'Header text for the related topics surface generically',
	},
	ai_topics_surface_header_person: {
		id: 'search_ai.ai_topics.surface.person_header',
		defaultMessage: 'Topics {userName} knows about',
		description: 'Header text for the topics surface in person cards',
	},
	ai_topics_surface_header_beta_lozenge: {
		id: 'search_ai.ai_topics.related_topics.header_beta_lozenge',
		defaultMessage: 'BETA',
		description: 'Text for the related topics header beta lozenge',
	},
	ai_topics_surface_footer_beta_lozenge: {
		id: 'search_ai.ai_topics.related_topics.footer_beta_lozenge',
		defaultMessage: 'BETA',
		description: 'Text for the related topics footer beta lozenge',
	},
	ai_topics_surface_footer_content_may_vary: {
		id: 'search_ai.ai_topics.related_topics.footer_beta_lozenge',
		defaultMessage: 'Content quality may vary',
		description: 'Text for the related topics footer content may vary text',
	},
	ai_topics_surface_footer_powered_by_ai: {
		id: 'search_ai.ai_topics.related_topics.footer_powered_by_ai',
		defaultMessage: 'Powered by AI',
		description: 'Text for the related topics footer powered by AI text',
	},
	ai_topics_user_feedback_prompt: {
		id: 'search_ai.ai_topics.feedback.prompt.user',
		defaultMessage: 'Are these topics correctly associated with this user?',
		description: 'Prompt text for the feedback component for user entity',
	},
	ai_topics_page_feedback_prompt: {
		id: 'search_ai.ai_topics.feedback.prompt.page',
		defaultMessage: 'Do these topics accurately reflect the contents of this page?',
		description: 'Prompt text for the feedback component for page entity',
	},
	ai_topics_page_feedback_button_text: {
		id: 'search_ai.ai_topics.feedback.prompt.button',
		defaultMessage: 'Submit feedback',
		description: 'Button text for the feedback collector',
	},
});
