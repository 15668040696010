import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import { logger } from '../common';

type SurfaceAttributes = {
	principalEntityId: string;
	principalEntityType: string;
	relatedTopics: string[];
	location: string;
	sessionId?: string;
};

export const onSurfaceShown = (surfaceAttributes: SurfaceAttributes): AnalyticsEventPayload => {
	try {
		return {
			action: 'shown',
			actionSubjectId: 'aiTopic',
			actionSubject: 'surface',
			eventType: 'track',
			attributes: {
				...surfaceAttributes,
			},
		};
	} catch (error) {
		logger.error(error);
		return {};
	}
};

type EntityClickAttributes = {
	clickedEntityId: string;
	clickedEntityType: string;
	principalEntityId: string;
	principalEntityType: string;
	sessionId?: string;
	location: string;
};

export const onRelatedEntityClick = (
	entityClickAttributes: EntityClickAttributes,
): AnalyticsEventPayload => {
	try {
		return {
			action: 'clicked',
			actionSubjectId: 'aiTopic',
			actionSubject: 'relatedEntity',
			eventType: 'ui',
			attributes: {
				...entityClickAttributes,
			},
		};
	} catch (error) {
		logger.error(error);
		return {};
	}
};
