// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { N0, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { READING_AIDS_DIALOG_WIDTH } from '../constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-empty-styled-expression -- Ignored via go/DSP-18766
export const ReadingAidsWrapper = styled.div({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsContainer = styled.div({
	position: 'relative',
	borderRadius: 'inherit',
	maxHeight: '650px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: READING_AIDS_DIALOG_WIDTH,
	backgroundColor: token('elevation.surface.overlay', N0),
	overflowX: 'hidden',
	overflowY: 'scroll',
	scrollbarWidth: 'none',
	MsOverflowStyle: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::-webkit-scrollbar': {
		display: 'none',
	},
});

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ReadingAidsPopupContainer = styled.div<{
	isLoading?: boolean;
	continueAnimation?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ isLoading, continueAnimation }) => ({
	position: 'relative',
	borderRadius: '5px',
	boxShadow: token('elevation.shadow.overlay', `0 4px 8px -2px ${N60A}, 0 0 1px ${N60A}`),
	padding: token('space.025', '2px'),
	'@property --gradient-angle': {
		syntax: "'<angle>'",
		initialValue: '45deg',
		inherits: 'false',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes rotationAnimation': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%': {
			'--gradient-angle': '0deg',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'100%': {
			'--gradient-angle': '360deg',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	animation: isLoading || continueAnimation ? 'rotationAnimation linear 3s infinite' : '',
	background:
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		isLoading || continueAnimation
			? 'conic-gradient( from var(--gradient-angle, 45deg), #BF63F3, #FFA900 25%, #0469FF 67%, #0065FF, #BF63F3)'
			: token('elevation.surface.overlay', N0),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsPopupContainerGlow = styled(ReadingAidsPopupContainer)({
	position: 'absolute',
	filter: 'blur(25px)',
	opacity: 0.5,
	height: '100%',
});
